const fields = {
    lessons: {
        Id: {
            Order: 1,
            Display: 'מזהה',
            Id: 'id',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        CourseNumber: {
            Order: 2,
            Display: 'מספר קורס',
            Id: 'courseId',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
        },
        LessonName: {
            Order: 3,
            Display: 'שם יחידת הלימוד',
            Id: 'name',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        LessonColor: {
            Order: 4,
            Display: 'צבע',
            Id: 'btnColor',
            SortField: 'Type',
            Type: 'FieldColor',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        Status: {
            Order: 5,
            Display: 'פעיל',
            Id: 'status',
            SortField: 'Type',
            Type: 'FieldBoolean',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        LessonType: {
            Order: 6,
            Display: 'סוג',
            Id: 'lessonType',
            SortField: 'Type',
            Type: 'FieldLessonType',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        SortOrder: {
            Order: 7,
            Display: 'סדר',
            Id: 'sortOrder',
            SortField: 'Type',
            Type: 'FieldSortOrder',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        IsHidden: {
            Order: 8,
            Display: 'מוסתר',
            Id: 'isHidden',
            SortField: 'Type',
            Type: 'FieldBoolean',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        Entity: {
            Order: 9,
            Display: 'ישות',
            Id: 'lessons',
            Type: 'FieldEntity',
            EntityType: 'יחידת לימוד'
        }
        // SupportingMaterials: {
        //     Order: 4,
        //     Display: 'חומרי עזר',
        //     Id: 'supportingMaterials',
        //     Type: 'FieldBoolean',
        //     Tooltip: 'יצרן הרכב',
        //     AdminOnly: false,
        //     Sortable: false,
        // },
    },
    books: {
        Id: {
            Order: 1,
            Display: 'מזהה',
            Id: 'id',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Title: {
            Order: 2,
            Display: 'כותרת',
            Id: 'title',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Subtitle: {
            Order: 3,
            Display: 'תת-כותרת',
            Id: 'subtitle',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Author: {
            Order: 4,
            Display: 'יוצר',
            Id: 'author',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Link: {
            Order: 5,
            Display: 'קישור',
            Id: 'link',
            Type: 'FieldCustomerLink',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Status: {
            Order: 6,
            Display: 'פעיל',
            Id: 'status',
            Type: 'FieldBoolean',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
    },
    courseBooks: {
        ImageUrl: {
            Order: 1,
            Display: 'תמונה',
            Id: 'imageUrl',
            Type: 'FieldImage',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Id: {
            Order: 2,
            Display: 'מזהה',
            Id: 'bookId',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        BookName: {
            Order: 3,
            Display: 'כותרת',
            Id: 'bookName',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        LessonName: {
            Order: 4,
            Display: 'יחידת לימוד',
            Id: 'lessonName',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        BookAuthor: {
            Order: 5,
            Display: 'יוצר',
            Id: 'bookAuthor',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        SortOrder: {
            Order: 6,
            Display: 'סדר',
            Id: 'sortOrder',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Status: {
            Order: 7,
            Display: 'פעיל',
            Id: 'status',
            Type: 'FieldBoolean',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
    },
    coupons: {
        Id: {
            Order: 1,
            Display: 'מזהה',
            Id: 'id',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Name: {
            Order: 2,
            Display: 'שם קופון',
            Id: 'name',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Code: {
            Order: 3,
            Display: 'קוד קופון',
            Id: 'code',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        DiscountPercentage: {
            Order: 4,
            Display: 'אחוז הנחה',
            Id: 'discountPercentage',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        DiscountAmount: {
            Order: 5,
            Display: 'הנחה בשקלים',
            Id: 'discountAmount',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        ExpirationDate: {
            Order: 6,
            Display: 'תאריך תפוגה',
            Id: 'expirationDate',
            Type: 'FieldDateHour',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        RemainingUses: {
            Order: 7,
            Display: 'מלאי',
            Id: 'remainingUses',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Status: {
            Order: 6,
            Display: 'פעיל',
            Id: 'status',
            Type: 'FieldBoolean',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
    },
    exerciseAnswers: {
        Id: {
            Order: 1,
            Display: 'מזהה',
            Id: 'id',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        UserName: {
            Order: 2,
            Display: 'שם התלמיד',
            Id: 'userName',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        ExerciseName: {
            Order: 3,
            Display: 'שם התרגול',
            Id: 'exerciseName',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        ReportUrl: {
            Order: 4,
            Display: 'כניסה לדו"ח תרגול',
            Id: 'reportUrl',
            Type: 'FieldCustomerLink',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Grade: {
            Order: 4.5,
            Display: 'ציון',
            Id: 'grade',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        MadadTakinEditor: {
            Order: 5,
            Display: 'טווח ציונים תקין',
            Id: 'madadTakinEditor',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        StarPercent: {
            Order: 6,
            Display: 'אחוז כוכביות',
            Id: 'starPercent',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        StarGrade: {
            Order: 7,
            Display: 'ציון כוכביות',
            Id: 'starGrade',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        GrayPercent: {
            Order: 8,
            Display: 'אחוז אפורות',
            Id: 'grayPercent',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        GrayGrade: {
            Order: 9,
            Display: 'ציון אפורות',
            Id: 'grayGrade',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Ahuzon: {
            Order: 10,
            Display: 'אחוזון',
            Id: 'ahuzon',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        EndDateTime: {
            Order: 11,
            Display: 'תאריך ביצוע',
            Id: 'endDateTime',
            Type: 'FieldDateHour',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        WasResearched: {
            Order: 12,
            Display: 'האם תוחקר',
            Id: 'wasResearched',
            Type: 'FieldExerciseResearch',
        },
        ExerciseQuestionsLink: {
            Order: 13,
            Display: 'קישור לשאלון',
            Id: 'exerciseQuestion',
            Type: 'FieldExerciseQuestions',
        },
        FieldExerciseAnswers: {
            Order: 14,
            Display: 'קישור לפתרון',
            Id: 'exerciseAnswer',
            Type: 'FieldExerciseAnswers',
        },
        FieldExerciseReset: {
            Order: 15,
            Display: 'איפוס תרגול',
            Id: 'exerciseReset',
            Type: 'FieldExerciseReset',
        },
    },
    videos: {
        Id: {
            Order: 1,
            Display: 'מזהה',
            Id: 'id',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        LessonId: {
            Order: 2,
            Display: 'מספר שיעור',
            Id: 'lessonId',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        VideoId: {
            Order: 3,
            Display: 'מספר וידאו',
            Id: 'videoId',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        SortOrder: {
            Order: 4,
            Display: 'סדר',
            Id: 'sortOrder',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Name: {
            Order: 5,
            Display: 'שם',
            Id: 'name',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Link: {
            Order: 6,
            Display: 'קישור',
            Id: 'link',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Link1: {
            Order: 7,
            Display: 'קישור',
            Id: 'link1',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        ExerciseCategory: {
            Order: 8,
            Display: 'קטגוריה',
            Id: 'exerciseCategoryName',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        VideoType: {
            Order: 9,
            Display: 'סוג',
            Id: 'videoTypeName',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        LawCollectionName: {
            Order: 8,
            Display: 'מקבץ חקיקה',
            Id: 'lawCollectionName',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        VerdictCollectionName: {
            Order: 9,
            Display: 'מקבץ פסיקה',
            Id: 'verdictCollectionName',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Category: {
            Order: 10,
            Display: 'קטגוריה',
            Id: 'categoryName',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        VideoCategory: {
            Order: 11,
            Display: 'נושא',
            Id: 'videoCategory',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Status: {
            Order: 12,
            Display: 'פעיל',
            Id: 'status',
            Type: 'FieldBoolean',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        IsInteractiveVideo: {
            Order: 13,
            Display: 'אינטרקטיבי',
            Id: 'isInteractiveVideo',
            Type: 'FieldBoolean',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
    },
    exercises: {
        Id: {
            Order: 1,
            Display: 'מזהה',
            Id: 'id',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        LessonId: {
            Order: 2,
            Display: 'מספר יחידת לימוד',
            Id: 'lessonId',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Name: {
            Order: 3,
            Display: 'יחידת לימוד',
            Id: 'lessonName',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        SortOrder: {
            Order: 4,
            Display: 'סדר',
            Id: 'sortOrder',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        ExerciseName: {
            Order: 5,
            Display: 'שם התרגול',
            Id: 'name',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        SubTitle: {
            Order: 6,
            Display: 'כותרת משנה',
            Id: 'subTitle',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        ExerciseType: {
            Order: 7,
            Display: 'סוג התרגול',
            Id: 'exerciseTypeId',
            Type: 'FieldExerciseType',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        CategoryName: {
            Order: 8,
            Display: 'נושא',
            Id: 'categoryName',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Status: {
            Order: 9,
            Display: 'פעיל',
            Id: 'status',
            Type: 'FieldBoolean',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Entity: {
            Order: 10,
            Display: 'ישות',
            Id: 'exercise',
            Type: 'FieldEntity',
            EntityType: 'תרגול'
        }
    },
    laws: {
        Id: {
            Order: 1,
            Display: 'מזהה',
            Id: 'id',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        LessonId: {
            Order: 2,
            Display: 'מספר שיעור',
            Id: 'lessonId',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        SortOrder: {
            Order: 3,
            Display: 'סדר',
            Id: 'sortOrder',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Name: {
            Order: 4,
            Display: 'שם',
            Id: 'name',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        LawCollectionName: {
            Order: 5,
            Display: 'מקבץ',
            Id: 'lawCollectionName',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Category: {
            Order: 6,
            Display: 'קטגוריה',
            Id: 'exerciseCategoryName',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Status: {
            Order: 7,
            Display: 'סטטוס',
            Id: 'status',
            Type: 'FieldBoolean',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
    },
    users: {
        Id: {
            Order: 1,
            Display: 'מזהה',
            Id: 'id',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        PrivateName: {
            Order: 2,
            Display: 'שם פרטי',
            Id: 'privateName',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        LastName: {
            Order: 3,
            Display: 'שם משפחה',
            Id: 'lastName',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        UserName: {
            Order: 4,
            Display: 'דוא"ל',
            Id: 'userName',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Phone: {
            Order: 5,
            Display: 'טלפון',
            Id: 'phone1',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Address: {
            Order: 6,
            Display: 'כתובת',
            Id: 'address',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Status: {
            Order: 7,
            Display: 'פעיל',
            Id: 'status',
            Type: 'FieldBoolean',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        CreateDate: {
            Order: 7,
            Display: 'תאריך יצירה',
            Id: 'createdate',
            Type: 'FieldDateHour',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        UserTypeId: {
            Order: 8,
            Display: 'גישה לעורך',
            Id: 'isAdminLinkAllowed',
            Type: 'FieldBoolean',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Entity: {
            Order: 9,
            Display: 'ישות',
            Id: 'user',
            Type: 'FieldEntity',
            EntityType: 'סטודנט'
        }
    },
    courseMessages: {
        messageTitle: {
            Order: 1,
            Display: 'שם',
            Id: 'messageTitle',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        messageBody: {
            Order: 2,
            Display: 'תיאור',
            Id: 'messageBody',
            Type: 'FieldHtml',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        messageAuthor: {
            Order: 3,
            Display: 'יוצר',
            Id: 'messageAuthor',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        displayFromDate: {
            Order: 4,
            Display: 'מתאריך',
            Id: 'displayFromDate',
            Type: 'FieldDateHour',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        displayToDate: {
            Order: 5,
            Display: 'עד תאריך',
            Id: 'displayToDate',
            Type: 'FieldDateHour',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        readCount: {
            Order: 6,
            Display: 'מספר סימוני קריאה',
            Id: 'readCount',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        showToGroupId: {
            Order: 7,
            Display: 'מוצג לקבוצה מספר',
            Id: 'showToGroupId',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        groupName: {
            Order: 8,
            Display: 'שם קבוצה',
            Id: 'groupName',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        status: {
            Order: 9,
            Display: 'פעיל',
            Id: 'status',
            Type: 'FieldBoolean',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
    },
    reports: {
        Id: {
            Order: 1,
            Display: 'מזהה',
            Id: 'id',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        CourseId: {
            Order: 2,
            Display: 'מספר קורס',
            Id: 'courseId',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        StudentId: {
            Order: 3,
            Display: 'מספר תלמיד',
            Id: 'studentId',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        StudentName: {
            Order: 4,
            Display: 'שם תלמיד',
            Id: 'studentName',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        ReportDate: {
            Order: 5,
            Display: 'תאריך דוח',
            Id: 'reportDate',
            Type: 'FieldDateTime',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
    },
    verdicts: {
        Id: {
            Order: 1,
            Display: 'מזהה',
            Id: 'id',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        LessonId: {
            Order: 2,
            Display: 'מספר שיעור',
            Id: 'lessonId',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        SortOrder: {
            Order: 3,
            Display: 'סדר',
            Id: 'sortOrder',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Name: {
            Order: 4,
            Display: 'שם',
            Id: 'name',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        VerdictSummaryName: {
            Order: 5,
            Display: 'תקציר',
            Id: 'verdictSummaryName',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        LawCollectionName: {
            Order: 6,
            Display: 'חוק',
            Id: 'lawName',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Category: {
            Order: 7,
            Display: 'קטגוריה',
            Id: 'exerciseCategoryName',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Status: {
            Order: 8,
            Display: 'סטטוס',
            Id: 'status',
            Type: 'FieldBoolean',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
    },
    questions: {
        Id: {
            Order: 1,
            Display: 'מזהה',
            Id: 'id',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Question: {
            Order: 2,
            Display: 'שאלה',
            Id: 'question',
            Type: 'FieldHtml',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        EditorNotes: {
            Order: 3,
            Display: 'הערות עורך',
            Id: 'editorNotes',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        DifficultyLevel: {
            Order: 4,
            Display: 'רמת קושי',
            Id: 'difficultyLevel',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        DifficultyPercentage: {
            Order: 5,
            Display: 'אחוז עונים נכונה',
            Id: 'difficultyPercentage',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        IsDelta: {
            Order: 6,
            Display: 'שאלת דלתא',
            Id: 'isDelta',
            Type: 'FieldBoolean',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        IsInExam: {
            Order: 7,
            Display: 'שאלת אמת',
            Id: 'isInExam',
            Type: 'FieldBoolean',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        PreviewQuestion: {
            Order: 8,
            Display: 'צפייה בשאלה',
            Id: 'PreviewQuestion',
            RouteName: 'question',
            Type: 'FieldPreviewQuestion',
            EntityType: 'שאלה'
        },
        Entity: {
            Order: 9,
            Display: 'ישות',
            Id: 'questions',
            RouteName: 'question',
            Type: 'FieldEntity',
            EntityType: 'שאלה',
            align: 'center',
        }
    },
    requests: {
        Id: {
            Order: 1,
            Display: 'מספר פנייה',
            Id: 'id',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Fullname: {
            Order: 2,
            Display: 'שם תלמיד',
            Id: 'fullname',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Email: {
            Order: 3,
            Display: 'מייל תלמיד',
            Id: 'email',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        UserId: {
            Order: 4,
            Display: 'מספר תלמיד',
            Id: 'userId',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        SourceType: {
            Order: 5,
            Display: 'מקור פנייה',
            Id: 'sentFromPage',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        Subject: {
            Order: 6,
            Display: 'נושא',
            Id: 'messagesInCourseType',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        Description: {
            Order: 7,
            Display: 'תיאור',
            Id: 'description',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        SubSubject: {
            Order: 8,
            Display: 'תת נושא',
            Id: 'questionSubject',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        QuestionNumber: {
            Order: 9,
            Display: 'מספר שאלה',
            Id: 'questionNumber',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
            align: 'center',
        },
        CreateDate: {
            Order: 10,
            Display: 'תאריך פתיחה',
            Id: 'createdDate',
            SortField: 'Type',
            Type: 'FieldDateHour',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        Status: {
            Order: 11,
            Display: 'סטטוס',
            Id: 'status',
            SortField: 'Type',
            Type: 'FieldRequestStatus',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        Chat: {
            Order: 12,
            Display: "פתיחת צ'אט",
            Id: "icon",
            Type: 'FieldRequestOpenChat'
        }
    },
    groups: {
        Id: {
            Order: 1,
            Display: 'מזהה',
            Id: 'id',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Name: {
            Order: 2,
            Display: 'שם',
            Id: 'name',
            Type: 'FieldStandard',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        CreateDate: {
            Order: 3,
            Display: 'תאריך יצירה',
            Id: 'createDate',
            Type: 'FieldDateHour',
            Tooltip: 'לוחית רישוי',
            AdminOnly: false,
            Sortable: false,
        },
        Entity: {
            Order: 4,
            Display: 'ישות',
            Id: 'group',
            Type: 'FieldEntity',
            EntityType: 'קבוצה'
        }
    },
    expoChapters: {
        Id: {
            Order: 1,
            Display: 'מזהה',
            Id: 'id',
            Type: 'FieldStandard',
            Tooltip: 'מזהה הלקוח',
        },
        DisplayName: {
            Order: 2,
            Display: 'שם פרק',
            Id: 'displayName',
            Type: 'FieldStandard',
            Tooltip: 'מזהה הלקוח',
        },
        UnitDisplayNameText: {
            Order: 3,
            Display: 'כותרת תווית יחידה',
            Id: 'unitDisplayNameText',
            Type: 'FieldStandard',
            Tooltip: 'מזהה הלקוח',
        },
        IntroText: {
            Order: 4,
            Display: 'פתיח',
            Id: 'introText',
            Type: 'FieldStandard',
            Tooltip: 'מזהה הלקוח',
        },
        Status: {
            Order: 5,
            Display: 'פעיל',
            Id: 'status',
            Type: 'FieldBoolean',
            Tooltip: 'מזהה הלקוח',
        },
        SortOrder: {
            Order: 6,
            Display: 'סדר',
            Id: 'sortOrder',
            Type: 'FieldStandard',
            Tooltip: 'מזהה הלקוח',
        },
        LessonExerciseId: {
            Order: 7,
            Display: 'תרגול מקושר',
            Id: 'lessonExerciseId',
            Type: 'FieldStandard',
            Tooltip: 'מזהה הלקוח',
        },
        ExpoType: {
            Order: 8,
            Display: 'סוג תרגול',
            Id: 'expoType',
            Type: 'FieldExpoType',
            Tooltip: 'מזהה הלקוח',
        },
        ExerciseCategoryName: {
            Order: 9,
            Display: 'קטגוריה',
            Id: 'exerciseCategoryName',
            Type: 'FieldStandard',
            Tooltip: 'מזהה הלקוח',
        },
        GroupName: {
            Order: 10,
            Display: 'קבוצה',
            Id: 'groupName',
            Type: 'FieldStandard',
            Tooltip: 'מזהה הלקוח',
        },
        Entity: {
            Order: 11,
            Display: 'ישות',
            Id: 'expoChapter',
            Type: 'FieldEntity',
            EntityType: 'פרק'
        }
    },
    expoChapterQuestions: {
        Id: {
            Order: 1,
            Display: 'מזהה',
            Id: 'id',
            Type: 'FieldStandard',
            Tooltip: 'מזהה הלקוח',
        },
        UnitDisplayName: {
            Order: 2,
            Display: 'תווית יחידה',
            Id: 'unitDisplayName',
            Type: 'FieldStandard',
            Tooltip: 'מזהה הלקוח',
        },
        Question: {
            Order: 3,
            Display: 'שאלה',
            Id: 'question',
            Type: 'FieldHtml',
            Tooltip: 'מזהה הלקוח',
        },
        Answer: {
            Order: 4,
            Display: 'תשובה נכונה',
            Id: 'answer',
            Type: 'FieldBoolean',
            Tooltip: 'מזהה הלקוח',
        },
        DetailedAnswer: {
            Order: 5,
            Display: 'פתרון',
            Id: 'detailedAnswer',
            Type: 'FieldHtml',
            Tooltip: 'מזהה הלקוח',
        },
        Status: {
            Order: 6,
            Display: 'פעיל',
            Id: 'status',
            Type: 'FieldBoolean',
            Tooltip: 'מזהה הלקוח',
        },
        Count: {
            Order: 7,
            Display: 'מספר עונים',
            Id: 'count',
            Type: 'FieldStandard',
            Tooltip: 'מזהה הלקוח',
        },
        CorrectPercent: {
            Order: 8,
            Display: '% מענה נכונה',
            Id: 'percent',
            Type: 'FieldStandard',
            Tooltip: 'מזהה הלקוח',
        },
    },
    lecturesRegions: {
        Id: {
            Order: 1,
            Display: 'מזהה',
            Id: 'id',
            Type: 'FieldStandard',
            Tooltip: 'מזהה הלקוח',
        },
        Name: {
            Order: 2,
            Display: 'שם',
            Id: 'name',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        Description: {
            Order: 2,
            Display: 'תיאור',
            Id: 'description',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        Status: {
            Order: 4,
            Display: 'פעיל',
            Id: 'status',
            SortField: 'Type',
            Type: 'FieldBoolean',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        SortOrder: {
            Order: 5,
            Display: 'סדר',
            Id: 'sortOrder',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
    },
    lecturesHalls: {
        Id: {
            Order: 1,
            Display: 'מזהה',
            Id: 'id',
            Type: 'FieldStandard',
            Tooltip: 'מזהה הלקוח',
        },
        Name: {
            Order: 2,
            Display: 'שם',
            Id: 'name',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        Address: {
            Order: 3,
            Display: 'כתובת',
            Id: 'address',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        City: {
            Order: 4,
            Display: 'עיר',
            Id: 'city',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        LecturesRegionName: {
            Order: 5,
            Display: 'אזור',
            Id: 'lecturesRegionName',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        Status: {
            Order: 5,
            Display: 'פעיל',
            Id: 'status',
            SortField: 'Type',
            Type: 'FieldBoolean',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
    },
    lecturesTeachers: {
        Id: {
            Order: 1,
            Display: 'מזהה',
            Id: 'id',
            Type: 'FieldStandard',
            Tooltip: 'מזהה הלקוח',
        },
        FullName: {
            Order: 2,
            Display: 'שם מלא',
            Id: 'fullName',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        LecturesCount: {
            Order: 3,
            Display: 'הרצאות בקורס',
            Id: 'lecturesCount',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        Status: {
            Order: 4,
            Display: 'פעיל',
            Id: 'status',
            SortField: 'Type',
            Type: 'FieldBoolean',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
    },
    lecturesFeedbackStatements: {
        Id: {
            Order: 1,
            Display: 'מזהה',
            Id: 'id',
            Type: 'FieldStandard',
            Tooltip: 'מזהה הלקוח',
        },
        Name: {
            Order: 2,
            Display: 'שם',
            Id: 'name',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        Description: {
            Order: 3,
            Display: 'תיאור',
            Id: 'description',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        TypeName: {
            Order: 4,
            Display: 'סוג',
            Id: 'typeName',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        Status: {
            Order: 5,
            Display: 'פעיל',
            Id: 'status',
            SortField: 'Type',
            Type: 'FieldBoolean',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        SortOrder: {
            Order: 6,
            Display: 'סדר',
            Id: 'sortOrder',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
    },
    lectures: {
        Id: {
            Order: 1,
            Display: 'מזהה',
            Id: 'id',
            Type: 'FieldStandard',
            Tooltip: 'מזהה הלקוח',
        },
        StartTime: {
            Order: 2,
            Display: 'בתאריך',
            Id: 'startTime',
            SortField: 'Type',
            Type: 'FieldDateHour',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        Day: {
            Order: 3,
            Display: 'יום',
            Id: 'day',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        SubjectName: {
            Order: 4,
            Display: 'נושא',
            Id: 'subjectName',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        TrackName: {
            Order: 5,
            Display: 'מסלול',
            Id: 'trackName',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        TeacherName: {
            Order: 6,
            Display: 'מרצה',
            Id: 'teacherName',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        Address: {
            Order: 7,
            Display: 'כתובת',
            Id: 'address',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        HallName: {
            Order: 8,
            Display: 'אולם',
            Id: 'hallName',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        Count: {
            Order: 9,
            Display: 'רשומים',
            Id: 'count',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        Status: {
            Order: 10,
            Display: 'פעיל',
            Id: 'status',
            SortField: 'Type',
            Type: 'FieldBoolean',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
    },
    lecturesLearningTracks: {
        Id: {
            Order: 1,
            Display: 'מזהה',
            Id: 'id',
            Type: 'FieldStandard',
            Tooltip: 'מזהה הלקוח',
        },
        Name: {
            Order: 2,
            Display: 'שם',
            Id: 'name',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        EditorNote: {
            Order: 3,
            Display: 'הערה פנימית',
            Id: 'editorNote',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        UserNote: {
            Order: 4,
            Display: 'הערה לתלמיד',
            Id: 'userNote',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        AvailableFrom: {
            Order: 5,
            Display: 'זמין מ',
            Id: 'availableFrom',
            Type: 'FieldDateHour',
            Tooltip: 'תאריך יצירת הלינק',
        },
        AvailableUntil: {
            Order: 6,
            Display: 'זמין עד',
            Id: 'availableUntil',
            Type: 'FieldDateHour',
            Tooltip: 'תאריך יצירת הלינק',
        },
        Status: {
            Order: 7,
            Display: 'פעיל',
            Id: 'status',
            SortField: 'Type',
            Type: 'FieldBoolean',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
        SortOrder: {
            Order: 8,
            Display: 'סדר',
            Id: 'sortOrder',
            SortField: 'Type',
            Type: 'FieldStandard',
            Tooltip: 'סוג הרכב פרטי/מסחרי כפי שמופיע במשרד הרישוי',
        },
    },
};

export default {
    list: (namespace) => ({ ...fields[namespace] }),
};
