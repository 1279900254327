import CoursesService from "@/services/CoursesService";
import store from '../store';
import LessonService from "./LessonService";
import AdminService from "./AdminService";

const DateSearchTypes = {
  DateTaken: {
    Value: 1,
    Text: 'תאריך קבלת הדו"ח',
    fromField: "FromDateTaken",
    toField: "ToDateTaken",
  },

  LastPayDate: {
    Value: 2,
    Text: "תאריך תשלום אחרון",
    fromField: "FromLastPayDate",
    toField: "ToLastPayDate"
  },

  PaymentDate: {
    Value: 3,
    Text: "תאריך תשלום דוח",
    fromField: "FromPaymentDate",
    toField: "ToPaymentDate"
  },

  ApprovalDate: {
    Value: 4,
    Text: "תאריך אישור תשלום",
    fromField: "FromApprovalDate",
    toField: "ToApprovalDate"
  },

  VerificationDate: {
    Value: 5,
    Text: "תאריך אימות",
    fromField: "FromVerificationDate",
    toField: "ToVerificationDate"
  },

  SignedDate: {
    Value: 6,
    Text: 'תאריך חתימת עו"ד על הסבה חברה',
    fromField: "FromSignedDate",
    toField: "ToSignedDate"
  },

  DriverSignedDate: {
    Value: 7,
    Text: 'תאריך חתימת עו"ד על הסבת נהג',
    fromField: "FromDriverSignedDate",
    toField: "ToDriverSignedDate"
  },

  CreateDate: {
    Value: 8,
    Text: "תאריך יצירה במערכת",
    fromField: "FromCreateDate",
    toField: "ToCreateDate",
    role: ["admin"]
  },
  TransferredDate: {
    Value: 9,
    Text: "תאריך הפקת מסמכי הסבת חברה",
    fromField: "FromTransferredDate",
    toField: "ToTransferredDate"
  },

  TransferCompleteDate: {
    Value: 10,
    Text: "תאריך השלמת הסבת חברה",
    fromField: "FromTransferCompleteDate",
    toField: "ToTransferCompleteDate"
  },

  DriverTransferCompleteDate: {
    Value: 11,
    Text: "תאריך השלמת הסבת נהג",
    fromField: "FromDriverTransferCompleteDate",
    toField: "ToDriverTransferCompleteDate"
  },
  VehicleCustomerContract: {
    Value: 12,
    Text: "תאריך חוזה פעיל",
    fromField: "FromDate",
    toField: "ToDate"
  },
  TransferredDriverDate: {
    Value: 13,
    Text: "תאריך הפקת מסמכי הסבת נהג",
    fromField: "FromDriverTransferredDate",
    toField: "ToDriverTransferredDate"
  },
  FirstDocumentTransferCompleteDate: {
    Value: 14,
    Text: "תאריך הפקה ראשונה של מסמכי הסבת חברה",
    fromField: "FromFirstTransferredDate",
    toField: "ToFirstTransferredDate"
  },
  FirstDocumentTransferDriverCompleteDate: {
    Value: 15,
    Text: "תאריך הפקה ראשונה של מסמכי הסבת נהג",
    fromField: "FromDriverFirstTransferredDate",
    toField: "ToDriverFirstTransferredDate"
  }
};

const TasksDateSearchTypes = {
  DueDate: {
    Value: 1,
    Text: "תאריך יעד",
    fromField: "FromDueDate",
    toField: "ToDueDate"
  },
  CreateDate: {
    Value: 2,
    Text: "תאריך יצירה",
    fromField: "FromDate",
    toField: "ToDate"
  },
  ReportDate: {
    Value: 2,
    Text: "תאריך דוח",
    fromField: "FromDate",
    toField: "ToDate"
  },
};

const fields = {
  LectureTrackSubjectId: {
    Display: "נושאים",
    Query: "LectureTrackSubjectId",
    Type: "FilterSelect",
    AllowEmpty: true,
    OptionsAsync: () =>
      CoursesService.getCourseLecturesLearningTrackSubjects(store.state.auth.course.id, 43,
        {
          PageSize: 999,
          IsAscending: true,
          PageNumber: 1,
          ResultType: 3
        },
        false).then((r) => r.data.items.map((u) => ({
            Value: u.id,
            Text: `${u.id} - ${u.name}`
          }))
        ),
  },
  Lessons: {
    Display: "יחידות לימוד",
    Query: "LessonIds",
    Type: "FilterMultiDropdown",
    AllowEmpty: true,
    OptionsAsync: () =>
      CoursesService.getCourse(store.state.auth.course.id,
        {
          PageSize: 999,
          IsAscending: true,
          PageNumber: 1,
          ResultType: 3
        },
        false).then((r) => r.data.items.map((u) => ({
            Value: u.id,
            Text: `${u.id} - ${u.name}`
          }))
        ),
  },
  Users: {
    Display: "משתמשים",
    Query: "UserIds",
    Type: "FilterMultiDropdown",
    AllowEmpty: true,
    OptionsAsync: () =>
    CoursesService.getUsersByCourse(store.state.auth.course.id, {
      PageSize: 5000,
      IsAscending: false,
      PageNumber: 1,
      ResultType: 3
    }).then((r) =>
        r.data.items.map((i) => ({ Value: i.id, Text: `${i.privateName} ${i.lastName} - ${i.userName}` }))
      ),
    Tooltip: "לוחית הרישוי של הרכב בזמן העבירה"
  },
  TaggedAdmin: {
    Display: "תיוג אדמין בפנייה",
    Query: "TaggedAdmin",
    Type: "FilterTypeAhead",
    AllowEmpty: true,
    OptionsAsync: (term) =>
      CoursesService.getUsersByCourse(store.state.auth.course.id, {
        Terms: term
      }).then((r) =>
        r.data.items.map((i) => ({ Value: i.userName, Text: `${i.privateName} ${i.lastName} - ${i.userName}` }))
      ),
    Tooltip: 'מספר התרגול ע"פ הרשות'
  },
  Exercises: {
    Display: "תרגולים",
    Query: "ExerciseIds",
    Type: "FilterMultiDropdown",
    AllowEmpty: true,
    OptionsAsync: () =>
    LessonService.getAdminExercises(store.state.auth.course.id, {
      PageSize: 5000,
      IsAscending: false,
      PageNumber: 1,
      ResultType: 3
    }).then((r) =>
        r.data.items.map((i) => ({ Value: i.id, Text: `${i.name} מתוך: ${i.lessonName}` }))
      ),
    Tooltip: "לוחית הרישוי של הרכב בזמן העבירה"
  },
  QuestionCategoryIds: {
    Display: "קטגוריות",
    Query: "QuestionCategoryIds",
    Type: "FilterMultiDropdown",
    AllowEmpty: true,
    OptionsAsync: () =>
      AdminService.getQuestionCategories().then((r) => r.data.map((u) => ({
            Value: u.id,
            Text: `${u.id} - ${u.name}`
          }))
        ),
  },
  ExerciseCategoryIds: {
    Display: "קטגוריות",
    Query: "ExerciseCategoryIds",
    Type: "FilterMultiDropdown",
    AllowEmpty: true,
    OptionsAsync: () =>
      CoursesService.getCategories().then((r) => r.data.map((u) => ({
            Value: u.id,
            Text: `${u.name}`
          }))
        ),
  },
  VideoTypeIds: {
    Display: "סוגים",
    Query: "VideoTypeIds",
    Type: "FilterMultiDropdown",
    AllowEmpty: true,
    OptionsAsync: () =>
      CoursesService.getVideoTypes().then((r) => r.data.map((u) => ({
            Value: u.id,
            Text: `${u.name}`
          }))
        ),
  },
  LawId: {
    Display: "חוקים",
    Query: "LawId",
    Type: "FilterSelect",
    AllowEmpty: true,
    OptionsAsync: () =>
      CoursesService.getLaws().then((r) => r.data.map((u) => ({
            Value: u.id,
            Text: `${u.id} - ${u.name}`
          }))
        ),
  },
  LawClauseId: {
    Display: "סעיף",
    Query: "LawClauseId",
    Type: "FilterSelect",
    AllowEmpty: true,
    OptionsAsync: () =>
      CoursesService.getLawClauses().then((r) => r.data.map((u) => ({
            Value: u.id,
            Text: `${u.id} - ${u.name}`
          }))
        ),
  },
  Groups: {
    Display: "קבוצות",
    Query: "GroupIds",
    Type: "FilterMultiDropdown",
    AllowEmpty: true,
    OptionsAsync: () =>
      LessonService.getGroups(store.state.auth.course.id,
        {
          PageSize: 999,
          IsAscending: false,
          PageNumber: 1,
          ResultType: 3
        },
        false).then((r) => r.data.items.map((u) => ({
            Value: u.id,
            Text: `${u.id} - ${u.name}`
          }))
        ),
  },
  LessonTypes: {
    Display: "סוג",
    Query: "LessonTypes",
    Type: "FilterMultiDropdown",
    AllowEmpty: true,
    OptionsAsync: () =>
      LessonService.getLessonTypes().then((r) => r.data.map((u) => ({
            Value: u.id,
            Text: `${u.name}`
          }))
        ),
  },
  Terms: {
    Display: "שם/טלפון/אימייל/מספר פנייה/תוכן",
    Query: "Terms",
    Type: "FilterString",
    AllowEmpty: true,
    Tooltip: 'מספר התרגול ע"פ הרשות'
  },
  QuestionsBankTerms: {
    Display: "מלל",
    Query: "Terms",
    Type: "FilterString",
    AllowEmpty: true,
    Tooltip: 'מספר התרגול ע"פ הרשות'
  },
  SentFromPage: {
    Display: "מקור פנייה",
    Query: "SentFromPage",
    Type: "FilterSelect",
    AllowEmpty: true,
    Options: [
      {
        Value: null,
        Text: "הכל"
      },
      {
        Value: "ContactUs.aspx",
        Text: "צור קשר"
      },
      {
        Value: "ExerciseStatistics.aspx",
        Text: "דוח תרגול"
      }
    ],
    Tooltip: 'מספר התרגול ע"פ הרשות'
  },
  MessageTypeId: {
    Display: "נושא פנייה מצור קשר",
    Query: "MessageTypeId",
    Type: "FilterSelect",
    AllowEmpty: true,
    OptionsAsync: () =>
    CoursesService.getRequestMessageTypes(store.state.auth.course.id).then((r) => r.data.items.map((u) => ({
            Value: u.id,
            Text: `${u.name}`
          }))
        ),
    Tooltip: 'מספר התרגול ע"פ הרשות'
  },
  Status: {
    Display: "סטטוס פנייה",
    Query: "Status",
    Type: "FilterSelect",
    AllowEmpty: true,
    Options: [
      {
        Value: null,
        Text: "הכל"
      },
      {
        Value: 1,
        Text: "חדשה"
      },
      {
        Value: 2,
        Text: "בטיפול"
      },
      {
        Value: 3,
        Text: "הסתיימה"
      }
    ],
    Tooltip: 'מספר התרגול ע"פ הרשות'
  },
  RespondingAdminId: {
    Display: "מענה של אדמין",
    Query: "RespondingAdminId",
    Type: "FilterTypeAhead",
    AllowEmpty: true,
    OptionsAsync: (term) =>
    CoursesService.getUsersByCourse(store.state.auth.course.id, {
      Terms: term
    }).then((r) =>
        r.data.items.map((i) => ({ Value: i.id, Text: `${i.privateName} ${i.lastName} - ${i.userName}` }))
      ),
    Tooltip: "לוחית הרישוי של הרכב בזמן העבירה"
  },
  AllowedPublication: {
    Display: "לפרסום",
    Query: "AllowedPublication",
    Type: "FilterBoolean",
    AllowEmpty: true,
    Tooltip: 'מספר התרגול ע"פ הרשות'
  },
  QuestionId: {
    Display: "מספר שאלה",
    Query: "QuestionId",
    Type: "FilterNumber",
    AllowEmpty: true,
    Tooltip: 'מספר התרגול ע"פ הרשות'
  },
  StudentId: {
    Display: "מספר תלמיד",
    Query: "StudentId",
    Type: "FilterNumber",
    AllowEmpty: true,
  },
  ExerciseId: {
    Display: "מספר תרגיל",
    Query: "ExerciseId",
    Type: "FilterNumber",
    AllowEmpty: true,
    Tooltip: 'מספר התרגול ע"פ הרשות'
  },
  AcademicInstituteId: {
    Display: "מוסד אקדמי",
    Query: "AcademicInstituteId",
    Type: "FilterSelect",
    AllowEmpty: true,
    OptionsAsync: () =>
      AdminService.getAcademicInstitudes().then((r) => r.data.map((u) => ({
            Value: u.id,
            Text: `${u.id} - ${u.name}`
          }))
        ),
    Tooltip: 'מספר התרגול ע"פ הרשות'
  },
  AuthorId: {
    Display: "מחבר",
    Query: "AuthorId",
    Type: "FilterSelect",
    AllowEmpty: true,
    OptionsAsync: () =>
      AdminService.getAuthors().then((r) => r.data.map((u) => ({
            Value: u.id,
            Text: `${u.id} - ${u.name}`
          }))
        ),
    Tooltip: 'מספר התרגול ע"פ הרשות'
  },
  MinimumStudentAnswers: {
    Display: "כמות עונים מינימלית",
    Query: "MinimumStudentAnswers",
    Type: "FilterNumber",
    AllowEmpty: true,
    Tooltip: 'מספר התרגול ע"פ הרשות'
  },
  HasOpenRequestsOnly: {
    Display: "שאלות עם פניות פתוחות",
    Query: "HasOpenRequestsOnly",
    Type: "FilterBoolean",
    AllowEmpty: true,
    Tooltip: 'מספר התרגול ע"פ הרשות'
  },
  IsActive: {
    Display: "פעילות בלבד",
    Query: "IsActive",
    Type: "FilterBoolean",
    AllowEmpty: true,
    Tooltip: 'מספר התרגול ע"פ הרשות'
  },
  IsDelta: {
    Display: "דלתא בלבד",
    Query: "IsDelta",
    Type: "FilterBoolean",
    AllowEmpty: true,
    Tooltip: 'מספר התרגול ע"פ הרשות'
  },
  IsInExam: {
    Display: "שאלות אמת בלבד",
    Query: "IsInExam",
    Type: "FilterBoolean",
    AllowEmpty: true,
    Tooltip: 'מספר התרגול ע"פ הרשות'
  },
  IsAcademy: {
    Display: "אקדמיה בלבד",
    Query: "IsAcademy",
    Type: "FilterBoolean",
    AllowEmpty: true,
    Tooltip: 'מספר התרגול ע"פ הרשות'
  },
  IsInactive: {
    Display: "לא פעילות בלבד",
    Query: "IsInactive",
    Type: "FilterBoolean",
    AllowEmpty: true,
    Tooltip: 'מספר התרגול ע"פ הרשות'
  },
  HasEditorNotesOnly: {
    Display: "שאלות עם הערות עורך",
    Query: "HasEditorNotesOnly",
    Type: "FilterBoolean",
    AllowEmpty: true,
    Tooltip: 'מספר התרגול ע"פ הרשות'
  },
  MinimumDifficulty: {
    Display: "רמת קושי מינימלית",
    Query: "MinimumDifficulty",
    Type: "FilterNumber",
    AllowEmpty: true,
    Tooltip: 'מספר התרגול ע"פ הרשות'
  },
  MaximumDifficulty: {
    Display: "רמת קושי מקסימלית",
    Query: "MaximumDifficulty",
    Type: "FilterNumber",
    AllowEmpty: true,
    Tooltip: 'מספר התרגול ע"פ הרשות'
  },
  MuniIdNumber: {
    Display: "מזהה עיריה",
    Query: "MuniIdNumber",
    Type: "FilterString",
    AllowEmpty: true,
    Tooltip: 'מספר מזהה עיריה'
  },
  PostOfficeReference: {
    Display: "מספר מעקב",
    Query: "PostOfficeReference",
    Type: "FilterString",
    AllowEmpty: true,
    Tooltip: 'מספר מעקב ע"פ דואר ישראל'
  },
  Other: {
    Display: "אחר",
    Query: "SearchTerm",
    Type: "FilterString",
    AllowEmpty: true,
    Tooltip: "חיפוש כללי"
  },
  DateTypes: {
    IsComplex: true,
    Display: "סוג חיפוש",
    Id: "DateTypes",
    Type: "FilterDateTypes",
    Tooltip: "חיפוש לפי סוג תאריך",
    Options: [
      { ...DateSearchTypes.DateTaken },
      { ...DateSearchTypes.LastPayDate },
      { ...DateSearchTypes.PaymentDate },
      { ...DateSearchTypes.ApprovalDate },
      { ...DateSearchTypes.VerificationDate },
      { ...DateSearchTypes.CreateDate },
      { ...DateSearchTypes.SignedDate },
      { ...DateSearchTypes.DriverSignedDate },
      { ...DateSearchTypes.TransferredDate },
      { ...DateSearchTypes.TransferredDriverDate },
      { ...DateSearchTypes.TransferCompleteDate },
      { ...DateSearchTypes.DriverTransferCompleteDate },
      { ...DateSearchTypes.FirstDocumentTransferCompleteDate },
      { ...DateSearchTypes.FirstDocumentTransferDriverCompleteDate }
    ]
  },

  // Tasks
  AssigneeUserId: {
    Display: "משתמש משוייך",
    Query: "AssigneeUserId",
    Type: "FilterSelect",
    AllowEmpty: true,
    OptionsAsync: () =>
      CoursesService.users().then((r) =>
        r.data.map((u) => ({
          Value: u.Id,
          Text: u.FullName || u.UserName
        }))
      ),
    Tooltip: "החברה אליה הרכב משוייך בזמן קבלת הדוח"
  },
  TaskType: {
    Display: "סוג המשימה",
    Query: "TaskType",
    Type: "FilterSelect",
    AllowEmpty: false,
    Options: [
      {
        Value: null,
        Text: "הכל"
      },
      {
        Value: 1,
        Text: "שיחת טלפון"
      },
      {
        Value: 2,
        Text: "אימייל"
      },
      {
        Value: 3,
        Text: "לבצע"
      }
    ],
    Tooltip: 'סינון ע"פ סוג המשימה'
  },
  Priority: {
    Display: "דחיפות",
    Query: "Priority",
    Type: "FilterSelect",
    AllowEmpty: false,
    Options: [
      {
        Value: null,
        Text: "הכל"
      },
      {
        Value: 1,
        Text: "גבוהה"
      },
      {
        Value: 2,
        Text: "רגילה"
      },
      {
        Value: 3,
        Text: "נמוכה"
      }
    ],
    Tooltip: 'סינון ע"פ דחיפות המשימה'
  },
  SearchFilterType: {
    IsComplex: true,
    Display: "סוג חיפוש",
    Id: "SearchFilterType",
    Type: "FilterDateTypes",
    Tooltip: "חיפוש לפי סוג תאריך",
    Options: [{ ...TasksDateSearchTypes.CreateDate }]
  },
  SearchReportFilterType: {
    IsComplex: true,
    Display: "סוג חיפוש",
    Id: "SearchFilterType",
    Type: "FilterDateTypes",
    Tooltip: "חיפוש לפי סוג תאריך",
    Options: [{ ...TasksDateSearchTypes.ReportDate }]
  },

  // Drivers
  IdNumber: {
    Display: "תעודת זהות",
    Query: "IdNumber",
    Type: "FilterString",
    Tooltip: "תעודת זהות",
    AllowEmpty: true
  },
  FullName: {
    Display: "שם",
    Query: "FullName",
    Type: "FilterString",
    Tooltip: "שם",
    AllowEmpty: true
  },
  PhoneNumber: {
    Display: "טלפון",
    Query: "PhoneNumber",
    Type: "FilterString",
    Tooltip: "טלפון",
    AllowEmpty: true
  },
  City: {
    Display: "עיר",
    Query: "City",
    Type: "FilterString",
    Tooltip: "עיר",
    AllowEmpty: true
  },
  WorkerNumber: {
    Display: "מספר עובד",
    Query: "WorkerNumber",
    Type: "FilterString",
    Tooltip: "מספר עובד",
    AllowEmpty: true
  },
  TrackingIdNumber: {
    Display: "מספר מעקב",
    Query: "TrackingIdNumber",
    Type: "FilterString",
    Tooltip: "מספר מעקב",
    AllowEmpty: true
  },
  IsDriverLicenseExist: {
    Display: "קיים רשיון נהיגה",
    Query: "IsDriverLicenseExist",
    Type: "FilterSelect",
    AllowEmpty: false,
    Options: [
      {
        Value: null,
        Text: "הכל"
      },
      {
        Value: true,
        Text: "כן"
      },
      {
        Value: false,
        Text: "לא"
      }
    ],
    Tooltip: "קיים רשיון נהיגה"
  },
  // IsActive: {
  //   Display: "פעיל",
  //   Query: "IsActive",
  //   Type: "FilterSelect",
  //   AllowEmpty: false,
  //   Options: [
  //     {
  //       Value: null,
  //       Text: "הכל"
  //     },
  //     {
  //       Value: true,
  //       Text: "כן"
  //     },
  //     {
  //       Value: false,
  //       Text: "לא"
  //     }
  //   ],
  //   Tooltip: "פעיל"
  // }
};

export default {
  list: () => ({ ...fields }),
  DateSearchTypes
};
